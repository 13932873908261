import React from 'react'
import Button from '../../components/button'

type Props = {}

function ApplyButton({ }: Props) {
    return (
        <Button
        className='border-2 border-red-600 hover:border-red-500 rounded-full  text-red-600 px-8 py-2'
        >
            APPLY NOW
        </Button>
    )
}

export default ApplyButton