import { Link } from "gatsby";
import React, { ReactElement, useState } from "react";
import menuIcon from "../../images/menu-icon.svg";
import closeIcon from "../../images/close-icon.svg";
const styles = require("./index.module.css");
export interface IMenuItem {
  title: string;
  link: string;
  items?: IMenuItem[]
}

export interface MainMenuProps {
  mainMenu: IMenuItem[];
}

export default function MainMenu({
  mainMenu,
}: MainMenuProps): ReactElement | null {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  React.useEffect(() => {
    if (typeof document !== "undefined") {
      if (isMenuOpen) {
        document.body.style.overflow = "hidden";
      } else {
        document.body.style.overflow = "auto";
      }
    }
  }, [isMenuOpen])

  const onMenuIconClick = (e: any) => {
    setIsMenuOpen(!isMenuOpen);
  };
  return (
    <>
      <div>
        <div onClick={() => setIsMenuOpen(!isMenuOpen)} className="p-2 cursor-pointer">
          <img
            src={menuIcon} />
        </div>
      </div>
      <div className={isMenuOpen ? "relative" : "hidden"}>

        <div className="fixed h-screen top-0 right-0 w-full">
          <div className="h-full  relative  md:pl-2 flex justify-end">
            <div
              onClick={() => setIsMenuOpen(false)}
              className="absolute  top-0 right-0 left-0 bottom-0 z-0" />
            <div
              className=" shadow-lg h-screen flex flex-col  relative z-10  bg-white w-full md:w-1/4"

            >
              <div
                onClick={() => setIsMenuOpen(false)}
                // style={{
                //   height: "10%"
                // }}
                className="w-full cursor-pointer flex justify-end p-4  bg-red-500 text-white">
                <div className="flex items-center">
                  <h4 className="pr-4">Close</h4>
                  <img src={closeIcon} />
                </div>
              </div>
              {mainMenu && mainMenu.length ? (
                <>
                  <ul className="overflow-auto pb-32">
                    {mainMenu.map((item: IMenuItem, index: number) => (
                      <>
                        <Link onClick={onMenuIconClick} to={item.link} key={item.title + index}>
                          <li className="cursor-pointer p-4 border-b border-gray-200 font-bold text-lg hover:bg-gray-100">
                            {item.title}
                          </li>
                        </Link>
                        {item.items && item.items.length ? <>
                          {item.items.map((item: IMenuItem, index: number) =>
                            <div className="p-4 hover:bg-gray-100 ">
                              <Link onClick={onMenuIconClick} className="" key={index + "" + index + item.title} to={item.link}>
                                <li className="px-4">{item.title}</li>
                              </Link>
                            </div>
                          )}
                        </> : ""}
                      </>
                    ))}
                  </ul>
                </>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>

      </div>
    </>
  );
}
