import { Link } from "gatsby";
import React, { ReactElement } from "react";
import { default as logo, default as logoFooter } from "../../images/logo.svg";
import ApplyButton from "../apply-button";
import MainMenu, { IMenuItem } from "../main-menu";
const styles = require("./index.module.css");
export interface HeaderProps {
  mainMenu: IMenuItem[];
  safeAreaPadding?: number;
  animated?: boolean;
}

const Header = React.forwardRef((props: HeaderProps, ref: any): ReactElement | null => {
  const logoRef = React.useRef<HTMLImageElement>();
  const [isScrolled, setIsScrolled] = React.useState(false);
  let tid = -1 as any;
  const handleAnim = (e) => {
    const { scrollingElement } = e.target as Document;
    if (scrollingElement) {
      const s = scrollingElement.scrollTop;
      if (s > 0) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    }

  }
  React.useEffect(() => {
    if (props.animated) {
      window.addEventListener('scroll', handleAnim);
      return () => { window.removeEventListener('scroll', handleAnim) }
    }
  }, [props.animated]);
  return (
    <>
      <div
        ref={ref}
        className={`${styles.wrapper} ${props.animated ? "" : ""} bg-transparent ${isScrolled ? "shadow-md" : ""} fixed w-full`}
        style={{
          minHeight: props.safeAreaPadding + "px",
          zIndex: 9999
        }}
      >
        <div className="py-2 px-2 md:px-8 bg-yellow-100 text-center text-xs md:text-md">
          Consumer Advisory: APR Rates Range From 5.99% to 35.99% Maximum APR for qualified consumers.
        </div>
        <div
          className="flex py-2 items-center px-2 md:px-8 relative -max-w-1366 mx-auto h-full"
        // style={{
        //   minHeight: props.safeAreaPadding + "px",
        // }}
        >
          <div className="">
            <Link to='/'>
              <img style={{ width: "120px" }} ref={logoRef} src={props.animated ? logoFooter : logo} />
            </Link>
          </div>
          <div className="flex flex-1 justify-end items-center">
            <Link to="/apply" className="md:mr-8">
              <ApplyButton />
            </Link>
            <div>
              <MainMenu mainMenu={props.mainMenu} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
});

export default Header;