import { Link } from "gatsby";
import React, { ReactElement } from "react";
import Button from "../../components/button/index";
import logo from "../../images/logo-footer.svg";

export interface FooterProps { menu: any }

export default function Footer({ menu }: FooterProps): ReactElement | null {
  const [cols, setCols] = React.useState([]);
  const colCount = 4;
  React.useEffect(() => {
    const _cols = Array(Math.ceil(menu.length / colCount)).fill(1);
    setCols(_cols);
  }, [menu])

  return (
    <>
      <div className="w-full text-gray-200 bg-red-900 pt-16">

        <div className="-max-w-1366   mx-auto p-4 text-justify">
          <h4 className="flex justify-center mb-4">
            <Link to="/">
              <img src={logo} />
            </Link>
          </h4>
          <div className="py-2">
            <h6 className="text-gray-200 text-center">Sitemap</h6>
            {menu && menu.length ?
              <div className="pt-2 flex  flex-col justify-center items-center">
                {cols.map((c: any, ci: number) =>
                  <div className="px-2 flex  flex-col md:flex-row justify-center items-center">
                    {menu.filter((m: any, index: number) => (index >= (colCount * (ci)) && index < colCount * (ci + 1))).map((m: any) =>
                      <Link to={m.link} className="px-2 py-2">{m.title}</Link>
                    )}
                  </div>
                )}
              </div> : ""}
          </div>
          <p>
            <br /><strong className="py-2">
              Legal Disclaimer</strong><br />
            Lending-Ground.com connects borrowers with lenders or lending partners and thus the specific terms and conditions of the specific lender or lending partner will apply to any loan a borrower takes out. We are compensated by these lenders or lending partners for connecting you with them, and the compensation received may affect which offer you are presented with. Any display of APR, loan amounts, interest or other loan details are estimations only, and actual amounts will vary by lender or lending partner and by borrower. Please note that some lenders or lending partners may perform credit checks as part of their credit transaction approval process. The lender or lending partner you connect with may not offer the best possible terms and borrowers should always compare all available options before making any decisions. In addition, you may be connected with a tribal lender. Tribal lenders’ rates and fees may be higher than state-licensed lenders, and are subject to federal and tribal laws, not state laws. THE OWNERS AND OPERATORS OF THIS WEBSITE ARE NOT LENDERS, they do not broker loans and they do not make loans or credit decisions. Nothing on this website is an offer or a solicitation to lend. Any information you submit to this site will be provided to a lender or lending partner. The operator of this website is not an agent, representative or broker of any lender or lending partner and does not endorse or charge you for any service or product.</p>
          <p>
            <br /><strong className="py-2">
              Availability</strong><br />
            Every state has its own set of rules and regulations that govern personal loan lenders. Your loan amount, APR and repayment term will vary based on your credit worthiness, state and lender or lending partner.</p>
          <p>
            <br /><strong className="py-2">
              Material Disclosure</strong><br />
            The operator of this website is not a lender, loan broker or agent for any lender or loan broker. This website is not an offer of credit nor is it a solicitation to lend. We are not affiliated with any lender. We are an advertising referral service for lenders that may be able to offer loans in amounts upto $50,000. There is no fee to use our services. Your loan request submitted on this website will be shared with one or more lenders. There is no guarantee that you will be connected with a lender, your loan request will be approved by a lender, or you will be offered the loan amount requested. Lenders may perform a credit check to determine your creditworthiness or verify your information. Any compensation we receive is paid by lenders and other advertising partners, and only for advertising services provided. Short-term, small-dollar loans should not be used as a long-term solution to financial hardship.</p>
        </div>
        <div className="text-xs py-4 text-center text-red-500">
          Copyrights @ Lending-Ground.com. All rights reserved.
        </div>
      </div>
    </>
  );
}
