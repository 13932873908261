import React, { ReactElement } from "react";

export interface ButtonProps extends React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement> {

}

export default function Button(props: ButtonProps): ReactElement | null {

  return (
    <button
      {...props}
    >
      {props.children}
    </button>
  );
}
