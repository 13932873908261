import React from "react";
import Header from "../../hoc/header";
import HelmetContainer from "../../hoc/helmet";
import { useStaticQuery, graphql } from "gatsby";
import loadingIcon from "../../images/loading.gif"
import Footer from "../../hoc/footer";


interface IDefaultLayoutProps {
  title: string;
  children?: any;
  animatedHeader?: boolean;
}

export default (props: IDefaultLayoutProps) => {

  const data = useStaticQuery(graphql`
    query DefaultLayoutQuery {
      site {
        siteMetadata {
          title
          mainMenu {
            title
            link
          }
          footerMenu {
            title
            link
          }
        }
      }
    }
  `);
  const { title, mainMenu, footerMenu } = data?.site?.siteMetadata || {};
  // const ref = React.useRef<HTMLDivElement>();
  const [safeAreaPadding, setSafeAreaPadding] = React.useState(100);
  // React.useEffect(() => {
  //   if (ref && ref.current) {
  //     setTimeout(() => {
  //       const { height } = ref.current.getBoundingClientRect();
  //       setSafeAreaPadding(height);
  //       if (document && typeof document !== "undefined") {
  //         document.getElementById("CHILDREN_CONTAINER").style.paddingTop = height + "px";
  //         // document.getElementById("BODY_OVERLAY").style.display = "none";
  //       }
  //     }, 1000)
  //   }
  // }, [ref])
  return (
    <>
      <HelmetContainer>
        <title>{title} : {props.title}</title>
        <script async src="https://www.googletagmanager.com/gtag/js?id=G-J6LZL91XJE"></script>
        <script>{`
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());

          gtag('config', 'G-J6LZL91XJE');
          `}
        </script>
      </HelmetContainer>
      {/* <div
        id="BODY_OVERLAY"
        className="bg-white fixed h-screen w-screen flex items-center justify-center"
        style={{ zIndex: 10000 }}
      >
        <div className="text-center">
          <img className="w-32" src={loadingIcon} /><br />
          <h1>
            LOADING
          </h1>
        </div>
      </div> */}
      <Header
        // ref={ref}
        animated={props.animatedHeader}
        // safeAreaPadding={safeAreaPadding}
        mainMenu={mainMenu} />

      <div
        id="CHILDREN_CONTAINER"
        style={{
          paddingTop: safeAreaPadding + "px",
        }}
      >{props.children}</div>
      <Footer menu={footerMenu} />
    </>
  );
};
