import React from 'react'
import Button from '../../components/button'
import arrowIcon from "../../images/arrow-icon.svg"

function FilledButton(props: any) {
    return (
        <Button {...props} className={`bg-red-500 hover:bg-red-400 text-white px-8 py-4 rounded-full flex items-center ${props.className || ""}`}  >
            <span className="pr-4">
                {props.children}
            </span>
            <img src={arrowIcon} />
        </Button>
    )
}

export default FilledButton